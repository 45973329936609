<template>
  <div :class="{ 'sidebar':true,  'phone': responsiveCheck() == 'tablet' || responsiveCheck() == 'phone', 'desk': responsiveCheck() == 'desk' || responsiveCheck() == 'laptop'}" id="sidebar">
    
    <div class="logo-details">
      <div class="logo_name">
      </div>
      <i class="bx bx-menu" id="btn-sidebar"  v-on:click="actionSidebar"></i>
    </div>
      <li class="nav-search">
        <i class="bx bxIcon bx-search" id="iconSearch"></i>
        <input type="text" id="searchSideBar" placeholder="Search..." v-on:keyup="searchSideBar()" autocomplete="off"/>
        <i class="bx bx-x"  v-on:click="clearSearchSideBar()"></i>
        <span class="tooltip">Search</span>
      </li>
    <ul class="nav-list" id="nav-list">
      <li v-for="(item, index) in menu" :key="index">
        <div class="module" v-if="item.attributeType == 'Group'" >
          <div class="module-block">  
            <i class="bx bx-grid-alt"></i>
            <span data-toggle="collapse" :href="'#module-group'+index" role="button" aria-expanded="false" aria-controls="collapseExample">
              {{item.attributeName}} 
            </span>
          </div>
          <div class="collapse module-group" :id="'module-group'+index" v-if="item.attributeType == 'Group'" >
            <div v-for="(modules, vindex) in item.smartComponents" :key="vindex">
              <span href="" v-if="modules.metaType">
                <a :href="modules.url" style="color: #fff;">
                  <i class='bx bxs-right-arrow' />
                  {{ setNameModule(modules) }} 
                </a>
              </span>
              <span :id="modules.SK" v-else v-on:click="setPanel(modules); actionSidebar()"  ><i class='bx bxs-right-arrow' /> 
                {{ setNameModule(modules) }}
              </span>
            </div>
          </div>
        </div>
        <span class="tooltip" v-if="item.attributeType == 'Group'">{{item.attributeName}}</span>
      </li>
    </ul>
  </div>
</template>
<script>
//Others

//Tools
import Vuex from "vuex";
import { API, Storage } from 'aws-amplify'
import {responsiveCheck, actionSidebar} from '../store/tools'

//AWS - GRAPHQL
import { smd_getRecordObj,smd_listEntity  } from '../graphql/queries'
import PaperDisplayer from '../components/papers/PaperDisplayer.vue'

export default {
  name: "sidebar",
  components:{
    PaperDisplayer
  },
  data() {
    return {
      logo: {url:'',style:''},
      preloadList:[],
    }
  },
  created() {
    //setTimeout(() => {this.setLogo()}, 2000);
    
  },
  methods: {
    //Global Methods
    ...Vuex.mapMutations(["setModule",'setModuleLayout',"SetOrganizationID","SetPageForm","SetPageTable","SetPageName","SetPageQuery", "SetPageMeta", "SetPageModule", "SetPageEntity", "setAllowSelectPK",'setPreloadList']),
    ...Vuex.mapActions(["getPreloadList","getRecordLists"]),
    //TOOLS
    loading(action){
      //console.log('loading: '+action);
      if (action == 'show') {
        document.getElementById("loading").classList.add('show')
        document.getElementById("body").classList.add("noScroll")
      }else{
        document.getElementById("loading").classList.remove('show')
        document.getElementById("body").classList.remove("noScroll")
      }
    },
    responsiveCheck(){
      return responsiveCheck()
    },
    //DB
    async setPanel(data){
      console.clear()
      this.preloadList = []
      //this.loading('show')
      console.log('Data Layout:',data);
      let moduleData = ''
      if (this.menuModules.hasOwnProperty(data.SK)) {
        moduleData = this.menuModules[data.SK]
      }
      /*for (const key in this.modules) {
        //console.log(key,this.modules[data.SK]);
        if (this.modules[key].SK == data.SK) {
          console.log(this.modules[key]);
          moduleData = this.modules[key]
        }
      }*/
      if (moduleData.allowSelectPK == true && this.userPermissions.includes('Superadmin')) {
        setTimeout(() => {
          this.getPreloadList({metaJSON:{entityType: '', shortEntity: '#ORG', fieldID: 'SK', fieldValue: 'organization.code'}})
        }, 200);
      }
      this.setModuleLayout(data)
      this.setModule(moduleData)
      let pullData = ''
      if (moduleData['modules.dataQuery'] != '#SML') {
        pullData = await API.graphql({
          query: smd_getRecordObj,
          variables:{
            PK: moduleData['modules.formSK'].PK,
            SK: moduleData['modules.formSK'].SK,
          }
        })
        pullData = pullData.data.smd_getRecordObj
        let layout = JSON.parse(pullData.data)['smartlayout.Layout'].value
        if (layout.includes('preloadList') ) {
          this.checkPreloadList(JSON.parse(layout))
        }
        //console.log(this.preloadLists);
      }
      this.SetPageQuery(moduleData['modules.dataQuery']);
      this.$router.push({ query: { name: moduleData['modules.dataQuery'] } })
      this.loading('hidden')
    },
    async checkPreloadList( list ){
      list.forEach(element => {
        if (element.inputType == 'preloadList' || element.inputType == 'structureMaker' ) {
          if (!this.preloadList.includes(element.metaJSON.shortEntity)) {
            this.getPreloadList(element)
            this.preloadList.push(element.metaJSON.shortEntity)
            //console.log(element.metaJSON);
          }
        }
        if (element.smartComponents.length != 0) {
          this.checkPreloadList(element.smartComponents)
        }
      });
    },
    //Page Functionality
    setNameModule(data){
      try {
        if (data.metaType) {
          return data.attributeName
        }else{
          return data.attributes['modules.pageName'].value
        }
      } catch (error) {
        console.log();
        console.log(error);
        return 'NONE'
      }
    },
    actionSidebar() {
      actionSidebar()
    },
    searchSideBar(){
      let value = document.getElementById('searchSideBar').value
      let nav =  document.getElementById('nav-list').children
      for (let index = 0; index < nav.length; index++) {
        try {
          let modules = nav[index].children[0].children[1].children
          for (let vindex = 0; vindex < modules.length; vindex++) {
            modules[vindex].classList.add('displayNone')
            if (modules[vindex].textContent.toLowerCase().includes(value.toLowerCase())) {
              if (!nav[index].children[0].children[1].classList[2]) {
                nav[index].children[0].children[1].classList.add('show')
              }
              modules[vindex].classList.remove('displayNone')
            }
          }
        } catch (error) {
          
        }
      }
    },
    clearSearchSideBar(){
      let input = document.getElementById('searchSideBar')
      input.value = ''
      console.log(input);
      this.searchSideBar()
    },
    
  },
  computed: {
    ...Vuex.mapState(['preloadLists','recordLists','userPermissions','modules','menu','menuModules'])
  },
}

</script>