<template>
   <paper-layout v-if="record != null" :layoutCategory="record.attributes[0].attributeValue" :filterSmarForm="record.attributes[7].attributeValue" id="layout" :layout="JSON.parse(record.attributes[1].attributeValue)" :models="{}" :data="{PK:record.PK,SK:record.SK}" />
</template>
<script>
   //AWS - GRAPHQL
   import {
      smd_getSmartLayoutByName,
   } from "../../graphql/queries";
   import { onNotify, onUpdateSmartRecord } from '../../graphql/subscriptions'
   //Tools
   import Vuex from "vuex";
   import { Auth, API,Storage } from "aws-amplify";
   import "amazon-connect-streams";
   import draggable from "vuedraggable";
   import Swal from 'sweetalert2'
   import { formatData, popUp, actionSidebar  } from "../../store/tools";

   //Components
   import Sidebar from "../../components/Sidebar.vue";
   import UiButton from '../../components/ui/UiButton.vue'
   import UiModal from '../../components/ui/UiModal.vue'
   import UiAccionButton from '../../components/ui/UiAccionButton.vue'
   import PaperDisplayer from "../../components/papers/PaperDisplayer.vue";
   import PaperLayout from "../../components/papers/PaperLayout.vue";
   import PaperModal from "../../components/papers/PaperModal.vue";
   import PaperClient from "../../components/papers/PaperClient.vue";

   export default {
      components: {
         Sidebar,
         PaperDisplayer,
         draggable,
         UiButton,
         UiAccionButton,
         UiModal,
         PaperModal,
         PaperLayout,
         PaperClient
      },
      data() {
         return {
            record: null,
         };
      },
      created() {
         this.getUser()
         console.log(this.$router.currentRoute.value.query.data);
      },
      methods: {
         ...Vuex.mapMutations(["setIdentityId","SetOrganizationID", "SetUser", "SetRows", "setDataUser",'setAttUser', 'setPublicOrganization','setUserPermissions' ,'setPublicOrganizationData','SetOrganizationData','setClasses','SetPermissions']),
         ...Vuex.mapActions(['getPublicOrganization','getRecordLists','getEntities','getProducts','initSmartdash']),
         //Init
         async getUser(){
            if (this.user == 'Not authorized') {
               Auth.signIn('smd_webuser', '383@JDU45ss#')
               .then((user) => { 
                  console.log(user);
                  //this.getPublicOrganization()//Alias
                  this.getLayout()
               })
               .catch((err) => console.log(err));
            }else{
               this.getLayout()
            }
         },
         async getLayout(){
            let pullData = null
            try {
               pullData = await API.graphql({
                  query: smd_getSmartLayoutByName,
                  variables: {
                     PK: '#SMARTDASH', name: this.$router.currentRoute.value.query.data
                  },
               });
               pullData = pullData.data.smd_getSmartLayoutByName
               this.record = JSON.parse(pullData.data)
               console.log(pullData);
            } catch (err) {
               console.log(err);
            }
         }
      },
      computed: {
         ...Vuex.mapState(["organizationID", "user", "entities", "dataUser", "organizationData","classes","recordLists", "notifications",'versionDash', 'recordSub','gRecord']),
      },
      watch: {
         
      },
   };

</script>